import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ title, description, author, keywords, lang, meta, image, url }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaAuthor = author || site.siteMetadata.author;
  const metaKeywords = keywords || site.siteMetadata.keywords;

  var  metaItems = [];
  metaItems[metaItems.length] = {name: `description`, content: metaDescription};
  metaItems[metaItems.length] = {name: `keywords`, content: metaKeywords};

  if (url) {
    metaItems[metaItems.length] = {property: `og:url`, content: url};
  }

  metaItems[metaItems.length] = {property: `og:title`, content: title};
  metaItems[metaItems.length] = {property: `og:description`, content: metaDescription};
  metaItems[metaItems.length] = {property: `og:type`, content: `website`};

  if (image && ('src' in image)) {
    metaItems[metaItems.length] = {property: `og:image`, content: image.src};
    if ('width' in image)
      metaItems[metaItems.length] = {property: `og:image:width`, content: image.width};
    if ('height' in image)
      metaItems[metaItems.length] = {property: `og:image:height`, content: image.height};
  }

  metaItems[metaItems.length] = {name: `twitter:card`, content: `summary`};
  metaItems[metaItems.length] = {name: `twitter:creator`, content: metaAuthor};
  metaItems[metaItems.length] = {name: `twitter:title`, content: title};
  metaItems[metaItems.length] = {name: `twitter:description`, content: metaDescription};
  metaItems = metaItems.concat(meta);

  return (
    <Helmet
      htmlAttributes={{lang}}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={metaItems}
   />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  keywords: `pozri video, videá,  pozrieť, objaviť, kultúra, história, zaujímavosti, príroda, osobnosti, objav, online marketing video marketing, regiony, slovensko`,
};

SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
